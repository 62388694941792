@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.flip-card
    display: flex
    flex:
        direction: column
    transition: all .2s
    color: inherit
    // Elems
    .flip-card__button
        border: none
        background-color: transparent
        position: absolute
        right: 0
        top: 0
        padding: $base-grid
        span
            display: flex
            align-items: center
            justify-content: center
            transition: all .2s
            width: $base-grid * 4
            height: $base-grid * 4
            border-radius: 50%
            z-index: 100
        &--add-to-favorites //Add Button
            span
                background-color: $add
                color: white
        &--remove-from-favorites //Check button
            span
                background-color: $check
                color: white
        @include focus
        
    .flip-card__icon
        display: inline-flex
        align-items: center
        justify-content: center
        width: $base-grid * 3
        height: $base-grid * 3
        border: none
            radius: 50%
        padding: calc($base-grid / 1.5)
        &--featured
            position: absolute

            //Unter dem hinzufügen button
            //right: $base-grid * 1.5
            //top: $base-grid * 6 

            //auf der linken seite
            left: $base-grid * 2
            top: $base-grid * 5
            background-color: #fdd017
            color: white
            box-shadow: 0 $base-grid $base-grid*2 $base-grid*-1.2 rgba(black, .5)
    .flip-card__image-area
        position: relative
        cursor: pointer
        background-color: white
        &:before
            content: ''
            display: block
            height: 110%
            width: 100%
            background-color: rgba(black, .5)
            position: absolute
            left: 50%
            top: 55%
            transform: translate(-50%, -45%)
            filter: blur(5px)
            transition: all .1s
            z-index: -1
            @include media-breakpoint-up(md)
                height: 120%
            @include media-breakpoint-up(lg)
                height: 118%
            @include media-breakpoint-up(xl)
                height: 120%
    .flip-card__flip-side
        backface-visibility: hidden
        background-color: $card-bg
        height: calc(100% - 45px)
        width: 100%
        transition: all .1s
        z-index: -1
        &--front
            position: absolute
            left: 0
            top: 0
            z-index: 0
        &--back
            z-index: -1
            display: flex
            flex-direction: column
            align-items: flex-start
            position: absolute
            left: 0
            bottom: 0
            padding: $base-grid
            @include focus
    .flip-card__image-area-link
        display: block
        position: relative
        padding:
            bottom: 140%
        border: 1px solid rgba(white , 0 )
        @include focus
        padding-top: 45px
        background-color: white
    .flip-card__image
        position: absolute
        left: 0
        top: 45px
        height: 100%
        width: 100%
        object-fit: cover

    .flip-card__image-icon
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        font-size: 2em
        color: lightgray
    .flip-card__info
        padding: $base-grid
        display: flex
        color: black
        background-color: white
        text-decoration: inherit
        cursor: pointer
        z-index: 1
    .flip-card__type
        display: inline-flex
        background-color: $gray-800
        color: white
        border-radius: calc($base-grid / 3)
        padding: calc($base-grid / 3) $base-grid
        font-size: .7em
        text-transform: capitalize
        pointer-events: none
        user-select: none
        transition: all .1s
        &--hide-flip
            position: absolute
            left: $base-grid
            top: $base-grid
            
    .flip-card__title
        width: 100%
        color: black
        word-wrap: break-word
        font:
            size: 0.9rem
        text-decoration: none
        transition: all .1s
        padding: $base-grid
        font-family: $font-family-base
        &--cutted
            height: 1.1rem * 2
            font:
                size: 1rem
            line-height: 1.1rem
            color: inherit
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            overflow: hidden
            word-break: break-word
            padding: 0
            z-index: -1
    .flip-card__unfold
        width: 100%
        color: black
        line-height: 1.1rem
        overflow: visible
        word-break: break-word
        font:
            size: 0.9rem
        text-decoration: none
        transition: all .1s
        padding: $base-grid
        font-family: $font-family-base
        z-index: 500
        position: absolute
        left: 0
        top: 0
           

    .flip-card__subtitle
        width: 100%
        color: inherit
        font:
            size: .8rem
        line-height: 1
        text-decoration: none
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
    .external-container
        display: flex
        width: 100%
        margin-top: $base-grid
        align-items: center
        justify-content: flex-end

    .external-link
        background-color: $primary
        color: $white
        font:
            size: .8rem
        font-weight: bold
        margin-bottom: $base-grid
        padding: calc($base-grid/4) calc($base-grid/2)
        text-decoration: none
        align-self: center
        @include focus-link
        &:hover
            background-color: $focus-bg-link
    // States
    &--flipped
        text-decoration: none
        color: inherit
        .flip-card__flip-area
            animation: 0s linear 0s flip-card
                fill-mode: both
                direction: alternate
        .flip-card__flip-side
            &--back
                background-color: rgba(white, .95)
                z-index: 99
            &--front
                background-color: $card-bg
                z-index: -1
        .flip-card__info
            background-color: white
        .flip-card__icon
            opacity: 0
        .flip-card__title--cutted
            opacity: 0


//@keyframes flip-card
//    100%

