@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.filters
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    .headline
        display: flex
        padding: $base-grid * 2
        margin: 0
        color: white
    .filters__all-close
        border: none
        background-color: $filter-header-bg
        padding: $base-grid $base-grid * 4
        margin: $base-grid * 2
        
    .filter-header
        background-color: $filter-header-bg
        display: grid
        padding: 0
        padding-right: 0px
        grid: 
            template:
                rows: auto auto
                areas: 'sticky' 'categories'
            gap: $base-grid * 3
        align-items: start
        /*@include media-breakpoint-up(lg)
            grid: 
                template:
                    columns: 8fr 2fr 42px max-content
                    rows: auto
                    areas: 'categories search toggle clear'*/
        @media (min-width: 1280px)
            width: 1280px
            margin: 0 auto
        .filter-header__sticky
            position: sticky
            top: 0
            grid-area: sticky
            display: grid
            padding: 10px $base-grid * 2 0 $base-grid * 2
            background-color: $filter-header-bg
            grid: 
                template:
                    columns: 2fr 200px 42px max-content
                    rows: auto
                    areas: 'search switch toggle clear'
                gap: $base-grid * 3
            align-items: start
            .filter-header__search
                grid-area: search
                justify-self: stretch
                background-color: white
                @include focus
            .filter-header__toggle
                grid-area: toggle
                padding: $base-grid
                @include focus
            .filter-header__switch
                grid-area: switch
                align-self: center
            .filter-header__clear
                grid-area: clear
                @include focus
            

        .filter-header__categories
            grid-area: categories
            justify-self: stretch
            padding: 0 $base-grid * 2 10px $base-grid * 2
            font:
                size: 1rem
        .filter-header__helper
            grid-area: helper
            align-self: start
            height: 0px
            column-gap: 0px
        // States
        &--opened
            grid: 
                template:
                    rows:  auto auto
                    areas: 'sticky' 'categories'
            overflow-y: auto
            max-height: 40vh
            padding: 0
            scrollbar-color: $scrollbar-color $scrollbar-bg
            scrollbar-width: thin
            

        &--opened::-webkit-scrollbar 
            width: 8px
            height: 8px

        &--opened::-webkit-scrollbar-track-piece  
            background-color: $scrollbar-bg


        &--opened::-webkit-scrollbar-thumb:vertical 
            height: 30px
            background-color: $scrollbar-color