@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.page
    min-height: -webkit-fill-available
    .page__body
        margin: 0
        overflow-x: hidden
        min-height: 100%
        min-height: -webkit-fill-available
    .page__header
        z-index: 1000
        background-color: white
        position: sticky
        top: 0
    .page__filter-row
        position: relative
        top: 0px
        z-index: 999
        flex-shrink: 0
        padding: 10px $base-grid * 2
        box-shadow: $base-grid $base-grid $base-grid*2  rgba(black, .05)
    .page__content
        min-height: calc(100vh - 100px - 124px - 59px)
        min-height: -webkit-fill-available
        padding:
            top: $base-grid * 4
            bottom: $base-grid * 4
    .page__loader, .page__content
        flex-grow: 10
        display: flex
        flex-direction: column
        align-items: stretch
    .page__hero
        overflow-x: hidden
        // @include media-breakpoint-down(md)
        //     background-color: #1F429D
.page__content.container
    margin-bottom: 60px