@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.desktop-collection__indicator
    position: absolute
    left: 35px
    font:
        size: .8rem
    font-family: $font-family-base
    background-color: $danger
    border-radius: 50%
    width: 18px
    height: 18px
    color: white
    text-align: center

.desktop-collection__indicator-big
    position: absolute
    left: 35px
    font:
        size: .8rem
    font-family: $font-family-base
    background-color: $danger
    border-radius: 50%
    width: 22px
    height: 22px
    color: white
    text-align: center
