@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.shelf-box
    display: grid
    grid:
        template:
            columns: minmax(0, 1fr)
            areas: 'shelveTitle' 'show-more' 'shelveDescription' 'shelveList'
        gap: $base-grid * 2
    @include media-breakpoint-up(sm)
        grid:
            template:
                columns: minmax(0, 1fr) minmax(0, 1fr)
                areas: 'shelveTitle show-more' 'shelveDescription shelveDescription' 'shelveList shelveList'
    @include media-breakpoint-up(lg)
        grid:
            template:
                columns: 9fr 3fr
                areas: 'shelveTitle show-more' 'shelveDescription shelveDescription' 'shelveList shelveList'
    @include media-breakpoint-up(xl)
        column-gap: $base-grid * 6
    // Elements
    .shelf-box__header-title
        grid-area: shelveTitle
        margin: 0
            bottom: $base-grid
    .shelf-box__description
        grid-area: shelveDescription
        margin: 0
    .shelf-box__show-more
        grid-area: show-more
        display: flex
        font-size: 14px
        margin: 0
        align-self: end
        justify-self: end
        align-items: center
        color: white
        font-weight: bold
        background-color: #004994
        padding: 4px 8px
        @include media-breakpoint-down(xs)
            align-self: start
            justify-self: start
        @include focus
        .arrow
            margin-left: $base-grid*2
    .shelf-box__list
        grid-area: shelveList
        padding: 0
        margin: 0 40px 0 40px
        @media (max-width: 1280px)
            margin: 0 40px 0 40px
        @media (max-width: 992px)
            margin: 0 50px 0 50px
        @media (max-width: 768px)
            margin: 0 0 0 0
        &--desktop
            display: grid
            grid:
                template:
                    columns: repeat(4, 1fr)
            @include media-breakpoint-up(lg)
                grid:
                    template:
                        columns: repeat(5, 1fr)
            @include media-breakpoint-up(xl)
                grid:
                    template:
                        columns: repeat(6, 1fr)
            gap: $base-grid * 2
        &--mobile
            overflow-x: auto
            display: flex
            gap: $base-grid * 2
            .shelf-box__card
                width: 220px
                flex-shrink: 0
    &:before
        grid:
            column: 1 / -1
            row: 1
        content: ''
        display: block
        height: 1px
        background-color: #D6D6D6
        justify-self: stretch
        align-self: end