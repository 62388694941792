@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.group-box
    display: grid
    grid:
        template:
            columns: minmax(0, 1fr)
    // Elements
    .group-box__header
        display: flex
        align-items: baseline
        justify-content: space-between
        margin-bottom: $base-grid
        border-bottom: 1px solid #D6D6D6
        
    .group-box__header-title
        // Nothing
        margin: 0
        @include media-breakpoint-down(xs)
            font:
                size: 1rem
    .group-box__header-title-link
        color: inherit
        @include focus-with-background
        
    .group-box__quantity
        font-size: $base-grid * 1.8
        display: flex
        align-items: center
        color: white
        font-weight: bold
        background-color: $primary
        padding: calc($base-grid/2) $base-grid
        text-decoration: none
        @include focus-link
        .arrow
            margin-left: $base-grid*2
    .group-box__list
        margin:
            top: $base-grid