@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.desktop-category-dropdown-button
    display: flex
    font-size: 15px
    @include media-breakpoint-up(md)
        font-size: 20px
    @include media-breakpoint-up(lg)
        font-size: 12px
    @include media-breakpoint-up(xl)
        font-size: 15px
    width: 100%
    align-items: center
    justify-content: space-between
    height: $base-grid * 5
    padding:
        left: $base-grid
        right: $base-grid
    background-color: white
    border: 1px solid #ECECEC
        radius: 2px
    @include focus-with-background        
    // Elements
    .desktop-category-dropdown-button__children
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
        word-break: break-all
    .desktop-category-dropdown-button__icon
        flex-shrink: 0