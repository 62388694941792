@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'

.head
    display: flex
    align-items: center
    justify-content: space-between
    margin-right: $base-grid * 2
    .headline
        margin-left: $base-grid * 2
        margin-bottom: 0
        color: $primary

.filter
    display: grid
    grid:
        template:
            columns: 1fr auto
            rows: repeat(auto-fit, 1fr)
            areas: 'backlink backlink' 'title title' 'counter counter' 'shelves shelves' 'description description' 'cards cards'
        column-gap: $base-grid * 2
    padding:
        left: $base-grid * 2
        right: $base-grid * 2
    max-width: $container-xxl
    width: 100%
    margin: 0 auto
    @include media-breakpoint-up(md)
        grid:
            template:
                areas: 'backlink backlink' 'title counter' 'view-funcs view-funcs' 'shelves shelves' 'description description' 'cards cards'
    @include media-breakpoint-up(lg)
        grid:
            template:
                columns: calc((100% - #{$container-lg}) / 2) repeat(2, 1fr) calc((100% - #{$container-lg}) / 2)
                areas: '. backlink backlink .' '. title title .' '. counter view-funcs .' 'shelves shelves shelves shelves' '. description . .' 'cards cards cards cards'
            row-gap: $base-grid
    @include media-breakpoint-up(xl)
        grid:
            template:
                columns: calc((100% - #{$container-xl}) / 2) 70ch 1fr calc((100% - #{$container-xl}) / 2)
    @media (min-width: 1280px)
        width: 1280px
        margin: 0 auto
    // Elements
    .filter__backlink
        grid-area: backlink
        align-self: start
        justify-self: start
        @include focus-with-background
    .filter__title
        grid-area: title
        margin: 0
    .filter__view-funcs
        grid-area: view-funcs
        justify-self: end
        display: flex
        gap: $base-grid * 3
        font-size: 16px
    .filter__formats-list
        grid-area: cards
        display: grid
        grid-gap: $base-grid * 5
        &--empty
            text-align: center
            justify-content: center
    /*&:before
        grid:
            column: 1 / -1
            row: 2
        content: ''
        display: block
        height: 1px
        background-color: #D6D6D6
        justify-self: stretch
        align-self: end
        */