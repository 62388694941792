@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.mobile-nav
    display: flex
    flex-direction: column
    position: sticky
    left: 0
    bottom: 0
    z-index: 1001
    width: 100%
    // Elements
    .mobile-nav__config-area
        overflow-y: auto
        background: linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .07), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .07), rgba(0, 0, 0, 0)) 0 100%
            size: 100% 40px, 100% 40px, 100% 14px, 100% 14px
            repeat: no-repeat
            attachment: local, local, scroll, scroll
            color: white
        z-index: -1
        height: calc(100vh - 65px)
        height: calc(var(--vh, 1vh) * 100 - 65px)
        
        // States
        &-enter 
            opacity: 0
            transform: translateY(100%)
        &-enter-active
            opacity: 1
            transform: translateY(0)
            //transition: all .4s .2s
        &-exit
            opacity: 1
        &-exit-active
            opacity: 0
            transform: translateY(100%)
            //transition: all .4s
    .mobile-nav__list
        list-style: none
        padding: 0
        margin: 0
        display: flex
        justify-items: stretch
        width: 100%
        border-top: 1px solid #E8E8E8
        background-color: white
        box-shadow: 0 -3px 10px -4px rgba(black, .11)
    .mobile-nav__list-item
        flex-grow: 1
        position: relative
        &:not(:last-child)
            &:after
                content: ''
                display: block
                width: 1px
                background: #E8E8E8
                height: $base-grid * 6
                position: absolute
                right: 0
                top: calc((100% - #{$base-grid * 6}) / 2)
    .mobile-nav__button
        width: 100%
        height: $base-grid * 8
        border: none
        background-color: transparent
        color: #989898
        &--active
            color: $blue-active--small
    .mobile-nav__button-icon
        display: inline-flex
        align-items: center
        justify-content: center
        height: $base-grid * 2.4
        width: $base-grid * 2.4
        border-radius: 50%
        vertical-align: sub
        transform: translateX(-50%)
        background-color: $blue-active--small
        color: white
        font-size: 10px
        margin-right: $base-grid * -1