@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.row-card
    $card-height: $base-grid * 9
    display: grid
    grid:
        template:
            columns: #{$card-height} auto #{$card-height}
            areas: 'image description button'
        gap: $base-grid
    width: 100%
    border:
        radius: $base-grid * 10
    transition: all .2s
    color: black
    overflow: hidden
    background-color: white
    // Elems
    .row-card__image-area
        grid-area: image
        align-self: center
        width: $card-height
        height: $card-height
        padding: 0
        border-radius: 50%
        overflow: hidden
        position: relative
    .row-card__image
        position: absolute
        left: 0
        top: 0
        height: 100%
        width: 100%
        object-fit: cover
    .row-card__image-icon
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        font-size: 2em
        color: lightgray
    .row-card__info
        grid-area: description
        align-self: center
        padding: $base-grid
        color: inherit
        text-decoration: inherit
        @include focus-with-background
        
    .row-card__type
        display: inline-block
        color: $gray-800
        font-size: .8em
        text-transform: capitalize
        pointer-events: none
        user-select: none
        line-height: 1
        & + .row-card__type
            margin-left: $base-grid
        &--featured
            background-color: #fdd017
            text-transform: none
            color: black
            padding: 3px
            border-radius: 2px
    .row-card__title
        width: 100%
        color: inherit
        font:
            size: 1rem
        line-height: 1.1
        height: 1.1rem * 2
        text-decoration: none
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        transition: all .2s
        word-break: break-word
        height: auto
        grid-area: title
        font-weight: bold
        @include focus-with-background
        
    .row-card__subtitle
        width: 100%
        color: inherit
        font:
            size: .8rem
        line-height: 1
        text-decoration: none
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
        margin-top: calc($base-grid / 2)
    .row-card__button
        grid-area: button
        align-self: center
        outline: none
        width: $card-height
        height: $card-height
        border: none
            radius: 50%
        transition: all .2s
        font-size: .6em
        background-color: transparent
        color: white
        @include focus
        
        span
            display: inline-flex
            align-items: center
            justify-content: center
            width: $base-grid * 4
            height: $base-grid * 4
            border-radius: 50%
        // States
        &--add-to-favorites span
            background-color: $add
        &--remove-from-favorites span
            background-color: $check
        @include focus
        
    // States
    &:hover
        text-decoration: none
        color: black
        background-color: white
        box-shadow: 0 2px 15px -7px rgba(black, .5)