@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.categories-list
    list-style: none
    margin: 0
    padding: 0
    background-color: white
     

    // Elements
    .categories-list__item
        padding: calc($base-grid / 2 )
        padding-left: $base-grid * 1.5
        .label
            margin: calc($base-grid / 2)
            cursor: pointer
            input
                cursor: pointer
        &:focus-within
            background-color: $focus-bg
            
.dropdown-item
    padding: calc($base-grid/2) $base-grid*2 !important
    white-space: normal !important
    