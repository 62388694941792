@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.settings
    display: grid
    grid:
        template:
            areas: 'title buttons' 'categories categories'
        gap: $base-grid * 2
    .settings__title
        grid-area: title
        padding:
            top: $base-grid * 2
            left: $base-grid * 2
        font:
            size: 1.5rem
    .settings__buttons
        grid-area: buttons
        padding:
            top: $base-grid * 2
            right: $base-grid * 2
        align-self: end
        justify-self: end
    .settings__categories
        grid-area: categories
        padding:
            left: $base-grid * 2
            right: $base-grid * 2