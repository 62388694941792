@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'

.header 
    display: flex
    flex-wrap: wrap
    align-items: center
    min-height: 65px
    width: 100%
    background-color: white
    @include media-breakpoint-up(sm) 
        min-height: 100px
    .header-container
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 0 15px 0 15px
        @include media-breakpoint-down(sm)
            padding: 15px
        @media (min-width: 1280px)
            width: 1280px
            margin: 0 auto
    // Elems
    .header__logo
        color: $primary
        display: flex
        flex-wrap: wrap
        text-align: left
        flex-grow: 1
        font:
            size: $h3-font-size
        @include media-breakpoint-up(md)
            font:
                size: $h1-font-size
        @include media-breakpoint-up(lg)
            font:
                size: $h1-font-size
        @include media-breakpoint-up(xl)
            font:
                size: 42px
        font-family: "DGUVMetaWeb Bold",Helvetica,Arial,sans-serif
        border-spacing: border-box
        @include focus-with-background
        
    .header__dropdown-collection
        margin:
            right: $base-grid
        padding: 0
        .my-link
            @include focus-with-background
            border: 1px solid white
            padding-left: 0
    // Elems
    .logo__mediathek
        display: inline-flex
        align-items: center
        // background-color: $primary
        // color: white
        @include media-breakpoint-up(md)
            margin: 0
        @include media-breakpoint-up(lg)
            margin: 0 0 0 100px
        .logo-container
            width: 55px
            height: 40px
            overflow: hidden
            display: inline-block
            @media (min-width: 576px)
                width: 160px
                height: 40px
                overflow: hidden
            @media (min-width: 1280px)
                width: 335px
                height: auto
        a
            @include focus-with-background   
        img
            width: 335px