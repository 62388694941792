@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.video-card
    display: flex
    flex:
        direction: column
    transition: all .2s
    color: inherit
    margin-bottom: $base-grid * 2
    // Elems
    .video-card__button
        border: none
        background-color: transparent
        position: absolute
        right: 0
        top: 0
        padding: $base-grid
        @include focus
        span
            display: flex
            align-items: center
            justify-content: center
            transition: all .2s
            width: $base-grid * 4
            height: $base-grid * 4
            border-radius: 50%
        &--add-to-favorites
            span
                background-color: $add
                color: white
        &--remove-from-favorites
            span
                background-color: $check
                color: white
    .video-card__icon
        display: inline-flex
        align-items: center
        justify-content: center
        width: $base-grid * 3
        height: $base-grid * 3
        border: none
            radius: 50%
        padding: calc($base-grid / 1.5)
        &--featured
            position: absolute
            left: $base-grid * 2
            top: $base-grid * 5
            background-color: #fdd017
            color: white
            box-shadow: 0 $base-grid $base-grid*2 $base-grid*-1.2 rgba(black, .5)
    .video-card__image-area
        position: relative
        box-shadow: $base-grid*.5 $base-grid $base-grid*2 rgba(black, .15) , 0 $base-grid $base-grid*2 $base-grid*-1 rgba(black, .3)
        transition: all .2s cubic-bezier(0.0, 0.0, 0.58, 1.0)
        background-color: white
        cursor: pointer
        &:before
            content: ''
            display: block
            height: 110%
            width: 100%
            background-color: rgba(black, .5)
            position: absolute
            left: 50%
            top: 55%
            transform: translate(-50%, -45%)
            filter: blur(5px)
            transition: all .1s
            @include media-breakpoint-up(md)
                height: 120%
            @include media-breakpoint-up(lg)
                height: 118%
            @include media-breakpoint-up(xl)
                height: 120%
    .video-card__image-area-link
        display: block
        position: relative
        padding:
            bottom: 140%
        @include focus
        border: 1px solid rgba(white , 0 )
        border-bottom: 1px solid white
        padding-top: 45px
        background-color: white
    .video-card__image
        position: absolute
        left: 0
        top: 45px
        height: 100%
        width: 100%
        object-fit: cover
    .video-card__image-icon
        position: absolute
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        font-size: 2em
        color: lightgray
    .video-card__info
        padding: $base-grid
        display: flex
        color: black
        background-color: white
        text-decoration: inherit
        cursor: pointer
        z-index: 1
    .video-card__type
        background-color: $gray-800
        color: white
        border-radius: calc($base-grid / 3)
        padding: calc($base-grid / 3) $base-grid
        position: absolute
        left: $base-grid
        top: $base-grid
        font-size: .8em
        text-transform: capitalize
        pointer-events: none
        user-select: none
        transition: all .2s
    .video-card__title
        width: 100%
        color: black
        word-wrap: break-word
        @include media-breakpoint-up(xs)
            font:
                size: 0.8rem
            line-height: 1.3
        @include media-breakpoint-up(xl)
            font:
                size: .9rem
            line-height: 1.1
        text-decoration: none
        transition: all .1s
        padding: $base-grid
        &--cutted
            height: 1.1rem * 2
            font:
                size: 1rem
            line-height: 1.1rem
            color: inherit
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            overflow: hidden
            word-break: break-word
            padding: 0
    .video-card__subtitle
        width: 100%
        color: inherit
        font:
            size: .8rem
        line-height: 1
        text-decoration: none
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical
        overflow: hidden
    // States
    &:hover
        text-decoration: none
        color: inherit
        .video-card__flip-area
            animation: .4s linear 0s both open_video-card