@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'



.desktop-view-button
    padding: calc($base-grid/2) $base-grid
    background: transparent
    text-decoration: none
    color: #000
    font-size: inherit
    font-weight: bold
    border: 1px solid $blue-active--big
    border-radius: 2px
    @include focus-with-background