@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../styles/config'
@import '~bootstrap/scss/mixins'


.my-link
    color: #686868
    text-decoration: none
    border: 0
    background-color: transparent
    display: flex
    align-items: center
    gap: $base-grid
    .my-link__icon
        display: inline-flex
        height: 45px
        width: 45px
        border-radius: 50%
        align-items: center
        justify-content: center
        background-color: $success
        color: white