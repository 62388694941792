@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.mobile-nav__config-area
    .dropdown-menu
        border: 0
        background-color: #EFEFEF
        border-radius: 0
        transform: none !important
        width: 100%
        -webkit-box-shadow: inset 0px 5px 10px -5px rgba(128, 128, 128,0.5) 
        box-shadow: inset 0px 5px 10px -5px rgba(128,128, 128,0.5)
        position: relative !important
        top: -16px !important
        z-index: 990 !important
            
.mobile-category-filter
    display: grid
    grid:
        template:
            areas: 'header' 'categories'
        gap: $base-grid * 2
    .mobile-category-filter__header
        position: sticky
        grid-area: header
        top: 0
        z-index: 2000
        background-color: white
        display: grid
        grid:
            template:
                areas: 'title close' 'buttons buttons' 'buttons buttons'
            gap: $base-grid * 2
        .mobile-category-filter__title
            grid-area: title
            padding:
                top: $base-grid * 2
                left: $base-grid * 2
            align-self: center
            font:
                size: 1.5rem
            
        .mobile-category-filter__close
            grid-area: close
            padding: $base-grid
            align-self: center
            text-align: right
        .close
            font:
                size: 2rem
            padding-right: $base-grid
            
            
        .mobile-category-filter__buttons
            grid-area: buttons
            padding:
                left: $base-grid * 2
            align-self: center
            justify-self: start
            display: flex
            flex-direction: row
            flex-wrap: wrap
    .mobile-category-filter__category
        display: flex
        flex-direction: column

    .mobile-category-filter__categories
        grid-area: categories
        width: 95%
        margin: auto
        display: flex
        flex-direction: column
        padding: calc($base-grid / 2)
    .mobile-category-dropdown-button
        margin-bottom: $base-grid *2
        height: 50px
        border-radius: 0
        -webkit-box-shadow: 0px 5px 10px -5px rgba(128, 128, 128,0.5) 
        box-shadow: 0px 5px 10px -5px rgba(128,128, 128,0.5)
    .mobile-category-filter__category-title
        margin-left: $base-grid * 2
    .mobile-category-filter__switch
        align-self: center
        margin: 0 0 $base-grid $base-grid
        width: 200px
