@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.footer
    width: 100%
    background-color: darken($body-bg, 5%)
    overflow: hidden
.footer__container
    padding: $base-grid
    background-color: darken($body-bg, 5%)
    @include media-breakpoint-up(sm)
        display: flex
        align-items: center
        justify-content: space-between
        padding-right: $base-grid * 3
    @media (min-width: 1280px)
        width: 1280px
        margin: 0 auto     
    // Elems
    .footer__copyright
        @include media-breakpoint-down(xs)
            padding-left: $base-grid * 2
            margin-top: $base-grid * 3
            font-size: .9em
.nav-link
    border-sizing: border-box
    @include focus-with-background
    padding: 7px 1rem
    border: 1px solid rgba(white ,0 )

