@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'



.shelves-list
    display: grid
    grid-area: shelves
    padding: 0
    @media (min-width: 1280px)
        max-width: 1280px !important
        margin: 12px auto
    grid:
        row-gap: $base-grid * 8
