@import '~bootstrap/scss/functions'
@import '~bootstrap/scss/variables'
@import './../../../styles/config'
@import '~bootstrap/scss/mixins'


.search
    display: flex
    .search__button
        border: 0
        background-color: $primary
        color: white
        width: 40px
        height: 40px
        @include focus-link
        
        
    .global-search
        display: flex
        flex-grow: 1
        align-items: center
        height: $base-grid * 5
        padding:
            left: $base-grid * 2
            right: $base-grid * 2
        background-color: white
        border: 1px solid #ECECEC
            radius: 2px
        background-color: $search-bg
        @include focus-with-background( $bg-color: white )    